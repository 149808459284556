import vars, {mobile} from './vars';

vars.$helperToggler.on('click', (event) => {
	event.stopPropagation();

	let $currentHelperToggler = $(event.currentTarget);
	let $currentHelper = $currentHelperToggler.parents('.helper');
	let $currentHelperId = $currentHelper.data('id');
	let $currentHelperPopup = $(`.helper-popup[data-id="${$currentHelperId}"]`);

	if ($currentHelper.data('position') === 'right') {
		vars.$scheme.removeClass('is-pushed-right').addClass('is-pushed-left');
	}

	if ($currentHelper.data('position') !== 'right') {
		vars.$scheme.removeClass('is-pushed-left').addClass('is-pushed-right');
	}

	vars.$helper.add(vars.$helperPopup).removeClass('is-active');
	vars.$body.addClass('is-helper-open');
	$currentHelper.add($currentHelperPopup).addClass('is-active');
});

vars.$helperPopupClose.on('click', (event) => {
	event.preventDefault();

	vars.$helper.add(vars.$helperPopup).removeClass('is-active');
	vars.$scheme.removeClass('is-pushed-right').removeClass('is-pushed-left');
	vars.$body.removeClass('is-helper-open');
});

if (!mobile) {
	vars.$helper.filter('.is-num-70').find('.js-helper-toggler').trigger('click');
}

// Close helper on body click
vars.$document.on('click', () => {
	if (vars.$body.hasClass('is-helper-open')) {
		vars.$helperPopup.filter('.is-active').find('.js-helper-popup-close').trigger('click');
	}
});

vars.$helperPopup.on('click', (e) => {
	e.stopPropagation();
});
