import {TimelineMax} from 'gsap/TweenMax';
import vars from './vars';

export default {
	open(id) {
		let $modal = vars.$modal.filter(`[data-modal="${id}"]`);
		let tl = new TimelineMax({
			onStart() {
				$modal.removeClass('is-visual-hidden');
			},
		});

		tl.fromTo($modal, 0.5, {
			autoAlpha: 0,
		}, {
			autoAlpha: 1,
			clearProps: 'all',
		});
	},

	close() {
		let $modal = vars.$modal.not('.is-visual-hidden');
		let tl = new TimelineMax({
			onComplete() {
				$modal.addClass('is-visual-hidden');
			},
		});

		tl.fromTo($modal, 0.5, {
			autoAlpha: 1,
		}, {
			autoAlpha: 0,
			clearProps: 'all',
		});
	},

	init() {
		vars.$modalClose.on('click', (event) => {
			event.stopPropagation();
			let $target = $(event.target);

			if ($target.data('close') === true) {
				vars.$html.removeClass('is-modal-opened');
				this.close();
			}
		});

		vars.$modalOpen.on('click', (event) => {
			event.preventDefault();
			this.close();
			let id = $(event.currentTarget).data('modal');

			vars.$html.addClass('is-modal-opened');
			this.open(id);
		});

		vars.$window.on('keydown', (event) => {
			if (event.keyCode === vars.KEY_ESC) {
				vars.$html.removeClass('is-modal-opened');
				this.close();
			}
		});
	},
};
