import vars from './vars';
const YTPlayer = require('yt-player');

function pauseAllVideoPlyers(players) {
	players.forEach((player) => {
		player.pause();
	});
}

if (vars.$reviewSlider.length) {
	let allVideos = [];

	vars.$reviewVideo.each((index, element) => {
		const player = new YTPlayer(element, {
			related: false,
			width: 500,
			height: 286,
			modestBranding: false,
			info: false,
			annotations: false,
			fullscreen: false,
			controls: true,
			captions: false,
			keyboard: false,
		});
		let $videoParent = $(element).parent();
		let videoId = $(element).data('video-id');
		let isPlayed = false;
		let isLoaded = false;

		allVideos.push(player);

		vars.$window.scroll(() => {
			let condition1 = vars.$window.scrollTop() > vars.$reviewSlider.offset().top - innerHeight / 2;
			let condition2 = vars.$window.scrollTop() < vars.$reviewSlider.offset().top + innerHeight / 2;
			let condition3 = $videoParent.parents('.slick-slide').hasClass('slick-active');

			if (vars.$window.scrollTop() > 100 && !isLoaded) {
				isLoaded = true;
				player.load(videoId);
				player.setVolume(0);
			}

			vars.$document.on('reviewSliderChangedVideo', (event, index) => {
				pauseAllVideoPlyers(allVideos);
				allVideos[index].play();
			});

			if (condition1 && condition2 && condition3 && !isPlayed) {
				pauseAllVideoPlyers(allVideos);

				isPlayed = true;
				player.play();
			} else if ((!condition1 || !condition2 || !condition3) && isPlayed) {
				isPlayed = false;
				player.pause();
			}
		});
	});
}

if (vars.$specialist.length) {
	let allVideos = [];

	vars.$document.on('specialists-video-load', () => {
		vars.$specialistVideo.each((index, element) => {
			const player = new YTPlayer(element, {
				related: false,
				width: 292,
				height: 496,
				modestBranding: true,
				info: false,
				annotations: false,
				fullscreen: false,
				controls: false,
				captions: false,
				keyboard: false,
			});
			let videoId = $(element).data('video-id');

			allVideos.push(player);
			player.load(videoId);
			player.setVolume(0);
		});
	});

	// Play Video
	vars.$specialistVideoPlay.on('click', (event) => {
		event.preventDefault();
		vars.$document.trigger('specialists-video-load');
		let id = $(event.currentTarget).parents('.person__video').data('video');
		let index = id - 1;

		$('.js-specialist').removeClass('is-playing');
		$(`.js-specialist-video-${id}`).parents('.js-specialist').addClass('is-playing');
		allVideos[index].play();
	});

	// Close Video
	vars.$specialistVideoClose.on('click', (event) => {
		event.preventDefault();
		let id = $(event.currentTarget).parents('.person__video').data('video');
		let index = id - 1;

		$('.js-specialist').removeClass('is-playing');
		allVideos[index].pause();
	});
}

if (vars.$doctorVideo.length) {
	let allVideos = [];

	vars.$doctorVideo.each((index, element) => {
		const player = new YTPlayer(element, {
			related: false,
			width: 855,
			height: 464,
			modestBranding: true,
			info: false,
			annotations: false,
			fullscreen: false,
			controls: false,
			captions: false,
			keyboard: false,
		});
		let videoId = $(element).data('video-id');

		allVideos.push(player);
		player.load(videoId);
		player.setVolume(0);
	});
}
