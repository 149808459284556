import 'slick-carousel';
import vars from './vars';

if (vars.$heroContentSlider.length && vars.$heroMediaSlider.length) {
	vars.$heroMediaSlider.on('init reInit beforeChange', (event, slick, currentSlide, nextSlide) => {
		// currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
		let i = (nextSlide ? nextSlide : 0) + 1;
		let direction = currentSlide + 1 > nextSlide ? 'prev' : 'next';

		vars.$hero.attr('data-theme-id', i);
		vars.$heroSliderStatus.text(`${i} / ${slick.slideCount}`);

		vars.$document.trigger('heroSlideChanged', [direction, i - 1]);
	});

	vars.$heroMediaSlider.slick({
		autoplay: false,
		autoplaySpeed: 4000,
		dots: true,
		arrows: false,
		fade: false,
		infinite: true,
		variableWidth: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		appendDots: vars.$heroSliderPagination,
		speed: 1000,
		accessibility: true,
		asNavFor: '.js-hero-content-slider',
	});

	vars.$heroContentSlider.slick({
		autoplay: false,
		autoplaySpeed: 4000,
		dots: false,
		arrows: false,
		fade: true,
		infinite: true,
		variableWidth: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 600,
		accessibility: true,
		asNavFor: '.js-hero-media-slider',
	});

	vars.$heroActionSlider.slick({
		dots: false,
		arrows: false,
		fade: true,
		infinite: true,
		variableWidth: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 600,
		accessibility: true,
	});

	vars.$document.on('heroSlideChanged', (event, direction, index) => {
		vars.$heroActionSlider.slick('slickGoTo', index);
	});
}

if (vars.$reviewSlider.length) {
	vars.$reviewSlider.on('init reInit beforeChange', (event, slick, currentSlide, nextSlide) => {
		// currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
		let i = (nextSlide ? nextSlide : 0) + 1;

		vars.$reviewSection.attr('data-theme-id', i);
		vars.$reviewSliderStatus.text(`${i} / ${slick.slideCount}`);

		vars.$document.trigger('reviewSlideChanged', i - 1);
	});

	vars.$reviewSlider.slick({
		dots: true,
		arrows: false,
		infinite: true,
		variableWidth: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		speed: 500,
		accessibility: true,
		appendDots: vars.$reviewSliderPagination,
		asNavFor: '.js-review-slider-nav',
	});

	vars.$reviewSliderNav.slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.js-review-slider',
		dots: false,
		arrows: false,
		variableWidth: true,
		focusOnSelect: true,
		responsive: [{
			breakpoint: 800,
			settings: {
				variableWidth: false,
				vertical: true,
			},
		}],
	});

	vars.$reviewActionSlider.slick({
		dots: false,
		arrows: false,
		fade: true,
		infinite: true,
		variableWidth: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 600,
		accessibility: true,
	});

	vars.$document.on('reviewSlideChanged', (event, index) => {
		vars.$reviewActionSlider.slick('slickGoTo', index);
		vars.$document.trigger('reviewSliderChangedVideo', [index]);
	});
}

if (vars.$certificateSlider.length) {
	vars.$certificateSlider.slick({
		dots: false,
		arrows: true,
		infinite: true,
		variableWidth: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		nextArrow: '<span class="slider__next slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 444.819 444.819"><path d="M352.025 196.712L165.884 10.848C159.029 3.615 150.469 0 140.187 0s-18.842 3.619-25.697 10.848L92.792 32.264c-7.044 7.043-10.566 15.604-10.566 25.692 0 9.897 3.521 18.56 10.566 25.981L231.545 222.41 92.786 361.168c-7.042 7.043-10.564 15.604-10.564 25.693 0 9.896 3.521 18.562 10.564 25.98l21.7 21.413c7.043 7.043 15.612 10.564 25.697 10.564 10.089 0 18.656-3.521 25.697-10.564L352.025 248.39c7.046-7.423 10.571-16.084 10.571-25.981.001-10.088-3.525-18.654-10.571-25.697z"/></svg></span>',
		prevArrow: '<span class="slider__prev slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 444.531 444.531"><path d="M213.13 222.409L351.88 83.653c7.05-7.043 10.567-15.657 10.567-25.841 0-10.183-3.518-18.793-10.567-25.835l-21.409-21.416C323.432 3.521 314.817 0 304.637 0s-18.791 3.521-25.841 10.561L92.649 196.425c-7.044 7.043-10.566 15.656-10.566 25.841s3.521 18.791 10.566 25.837l186.146 185.864c7.05 7.043 15.66 10.564 25.841 10.564s18.795-3.521 25.834-10.564l21.409-21.412c7.05-7.039 10.567-15.604 10.567-25.697 0-10.085-3.518-18.746-10.567-25.978L213.13 222.409z"/></svg></span>',
		appendArrows: vars.$certificateSliderControls,
		speed: 500,
		accessibility: true,
	});
}

if (vars.$carouselSlider.length) {
	vars.$carouselSlider.slick({
		dots: false,
		arrows: true,
		infinite: true,
		variableWidth: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		nextArrow: '<span class="slider__next slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 444.819 444.819"><path d="M352.025 196.712L165.884 10.848C159.029 3.615 150.469 0 140.187 0s-18.842 3.619-25.697 10.848L92.792 32.264c-7.044 7.043-10.566 15.604-10.566 25.692 0 9.897 3.521 18.56 10.566 25.981L231.545 222.41 92.786 361.168c-7.042 7.043-10.564 15.604-10.564 25.693 0 9.896 3.521 18.562 10.564 25.98l21.7 21.413c7.043 7.043 15.612 10.564 25.697 10.564 10.089 0 18.656-3.521 25.697-10.564L352.025 248.39c7.046-7.423 10.571-16.084 10.571-25.981.001-10.088-3.525-18.654-10.571-25.697z"/></svg></span>',
		prevArrow: '<span class="slider__prev slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 444.531 444.531"><path d="M213.13 222.409L351.88 83.653c7.05-7.043 10.567-15.657 10.567-25.841 0-10.183-3.518-18.793-10.567-25.835l-21.409-21.416C323.432 3.521 314.817 0 304.637 0s-18.791 3.521-25.841 10.561L92.649 196.425c-7.044 7.043-10.566 15.656-10.566 25.841s3.521 18.791 10.566 25.837l186.146 185.864c7.05 7.043 15.66 10.564 25.841 10.564s18.795-3.521 25.834-10.564l21.409-21.412c7.05-7.039 10.567-15.604 10.567-25.697 0-10.085-3.518-18.746-10.567-25.978L213.13 222.409z"/></svg></span>',
		appendArrows: vars.$carouselSliderControls,
		speed: 500,
		accessibility: true,
	});
}

if (vars.$aboutSlider.length) {
	vars.$aboutSlider.on('init reInit afterChange', (event, slick, currentSlide, nextSlide) => {
		// currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
		let i = (currentSlide ? currentSlide : 0) + 1;

		vars.$aboutSliderStatus.text(`${i} / ${slick.slideCount}`);
	});

	vars.$aboutSlider.slick({
		autoplay: true,
		autoplaySpeed: 2000,
		fade: true,
		dots: false,
		arrows: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: '<span class="about-slider__next slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.832 268.832"><path d="M265.171 125.577l-80-80c-4.881-4.881-12.797-4.881-17.678 0-4.882 4.882-4.882 12.796 0 17.678l58.661 58.661H12.5c-6.903 0-12.5 5.597-12.5 12.5 0 6.902 5.597 12.5 12.5 12.5h213.654l-58.659 58.661c-4.882 4.882-4.882 12.796 0 17.678 2.44 2.439 5.64 3.661 8.839 3.661s6.398-1.222 8.839-3.661l79.998-80c4.882-4.882 4.882-12.796 0-17.678z"/></svg></span>',
		prevArrow: '<span class="about-slider__prev slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400.004 400.004"><path d="M382.688 182.686H59.116l77.209-77.214c6.764-6.76 6.764-17.726 0-24.485-6.764-6.764-17.73-6.764-24.484 0L5.073 187.757c-6.764 6.76-6.764 17.727 0 24.485l106.768 106.775a17.252 17.252 0 0 0 12.242 5.072c4.43 0 8.861-1.689 12.242-5.072 6.764-6.76 6.764-17.726 0-24.484l-77.209-77.218h323.572c9.562 0 17.316-7.753 17.316-17.315 0-9.562-7.753-17.314-17.316-17.314z"/></svg></span>',
		appendArrows: vars.$aboutSliderControls,
		speed: 500,
		accessibility: true,
	});
}

if (vars.$contactsSliderDetails.length) {
	vars.$contactsSliderDetails.slick({
		dots: false,
		arrows: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 1,
		nextArrow: '<span class="contacts__slider__next slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.832 268.832"><path d="M265.171 125.577l-80-80c-4.881-4.881-12.797-4.881-17.678 0-4.882 4.882-4.882 12.796 0 17.678l58.661 58.661H12.5c-6.903 0-12.5 5.597-12.5 12.5 0 6.902 5.597 12.5 12.5 12.5h213.654l-58.659 58.661c-4.882 4.882-4.882 12.796 0 17.678 2.44 2.439 5.64 3.661 8.839 3.661s6.398-1.222 8.839-3.661l79.998-80c4.882-4.882 4.882-12.796 0-17.678z"/></svg></span>',
		prevArrow: '<span class="contacts__slider__prev slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400.004 400.004"><path d="M382.688 182.686H59.116l77.209-77.214c6.764-6.76 6.764-17.726 0-24.485-6.764-6.764-17.73-6.764-24.484 0L5.073 187.757c-6.764 6.76-6.764 17.727 0 24.485l106.768 106.775a17.252 17.252 0 0 0 12.242 5.072c4.43 0 8.861-1.689 12.242-5.072 6.764-6.76 6.764-17.726 0-24.484l-77.209-77.218h323.572c9.562 0 17.316-7.753 17.316-17.315 0-9.562-7.753-17.314-17.316-17.314z"/></svg></span>',
		appendArrows: vars.$contactsSliderControls,
		speed: 500,
		accessibility: false,
	});

	vars.$contactsSliderThumb.slick({
		dots: false,
		arrows: false,
		infinite: true,
		variableWidth: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 500,
		accessibility: false,
	});

	vars.$contactsSliderPhoto.slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 1,
		infinite: true,
		dots: false,
		arrows: false,
		focusOnSelect: false,
	});

	let $contactSliders = vars.$contactsSliderPhoto.add(vars.$contactsSliderDetails).add(vars.$contactsSliderThumb);
	let sliderAccess = true;
	let direction = 'slickNext';
	let lastSlideId = vars.$contactsSliderPhoto.find('.slick-slide').not('.slick-cloned').length - 1;

	$contactSliders.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
		if (!sliderAccess || currentSlide === nextSlide) {
			return false;
		}

		sliderAccess = false;
		if (nextSlide > currentSlide && currentSlide !== 0 || currentSlide === lastSlideId && nextSlide === 0) {
			direction = 'slickNext';
		} else if (nextSlide < currentSlide || currentSlide === 0 && nextSlide === lastSlideId) {
			direction = 'slickPrev';
		}

		setTimeout(() => {
			sliderAccess = true;
		}, 200);

		vars.$document.trigger('contactSlidersMove');

		return true;
	});

	vars.$document.on('contactSlidersMove', () => {
		$contactSliders.each((index, slider) => {
			$(slider).slick(direction);
		});
	});
}
